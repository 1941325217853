import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import format from "date-fns/format";
import { graphql, PageProps } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import AppContainer from "../../components/container";
import AppMarkdown from "../../components/markdown";
import AppPageTitle from "../../components/page-title";

const Content = styled.main(({ theme }) => ({
  padding: `0 ${theme.pagePadMobile}px`,
  marginBottom: theme.spacing(4),
  [theme.mq.desktop]: {
    padding: `0 ${theme.pagePad}px`,
  },
}));

const AppNews = ({ data }: PageProps<GatsbyTypes.NewsPageQuery>) => {
  const theme = useTheme();
  const news: GatsbyTypes.NewsItemFragment[] =
    data.allMarkdownRemark.edges.flatMap((edge) =>
      !!edge.node.frontmatter ? [edge.node.frontmatter] : []
    );

  return (
    <AppContainer
      flexDirection="column"
      alignItems="center"
      breakpoint="desktop"
    >
      <Helmet title="RWS | Nyheder"></Helmet>
      <AppPageTitle>Info og nyheder</AppPageTitle>
      <Content>
        {news
          .sort((a, b) => {
            return Date.parse(a.date ?? "") - Date.parse(b.date ?? "");
          })
          .flatMap(({ title, teaser, date, slug }) => {
            return [
              <article
                key={title}
                style={{
                  marginBottom: theme.spacing(2),
                }}
              >
                <h2
                  style={{
                    marginBottom: theme.spacing(1) / 2,
                    fontWeight: 600,
                    fontSize: 20,
                  }}
                >
                  {title}
                </h2>
                <span style={{ opacity: 0.7, fontSize: 14, fontWeight: 300 }}>
                  {format(new Date(date ?? ""), "dd/MM/yy")}
                </span>
                <AppMarkdown css={{ fontWeight: 300 }} content={teaser} />
                <a
                  style={{
                    fontSize: 15,
                  }}
                  href={`/info-og-nyheder/${slug}`}
                >
                  Læs mere
                </a>
              </article>,
            ];
          })}
      </Content>
    </AppContainer>
  );
};

export default AppNews;

export const pageQuery = graphql`
  query NewsPage {
    allMarkdownRemark(filter: { fields: { collection: { eq: "news" } } }) {
      edges {
        node {
          frontmatter {
            ...NewsItem
          }
        }
      }
    }
  }
`;
